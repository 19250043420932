<style lang="scss" scoped>
.pie-box {
  display: flex;
  .pie {
    position: relative;
    flex: 1;
    .icon {
      position: absolute;
      left: 50%;
      top: 200px;
      transform: translate(-50%, -50%);
      width: 66px;
      height: 66px;
    }
  }
  .vals {
    flex: 1;
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-size: 16px;
    color: #adadad;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-left: 38px;
        line-height: 40px;
        position: relative;
        div {
          display: flex;
          justify-content: space-between;
          span:first-child {
            margin-right: 10px;
          }
        }
        .stateDot {
          position: absolute;
          width: 14px;
          height: 14px;
          top: 12px;
          left: -28px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="pie-box">
    <div class="pie">
      <ve-pie
        :data="ehartData"
        :extend="echartOption"
        :events="echartEvents"
        :height="height"
      ></ve-pie>
      <svg class="icon" aria-hidden="true">
        <use :xlink:href="'#icon' + icon"></use>
      </svg>
    </div>
    <div class="vals">
      <ul v-show="showValue">
        <li v-for="(item, index) in ehartData.rows" :key="item.name">
          <i class="stateDot" :style="{ backgroundColor: modelColor[index] }"></i>
          <div>
            <span>{{ item.name }}</span>
            <span>{{ item.value }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VePie from 'v-charts/lib/pie.common'
import {
  colorPrimary7,
  colorPrimary3,
  colorPrimary8
} from '@/style/variables.scss'

export default {
  name: 'echart-pie',
  components: {
    VePie,
  },
  model: {
    prop: 'value',
    event: 'Change',
  },
  props: {
    value: Array,
    height: {
      type: String,
      default: '400px',
    },
    icon: {
      type: String,
      default: '-device-status'
    },
    modelColor: {
      type: Array,
      default() {
        return [colorPrimary7, colorPrimary3, colorPrimary8, '#FEA39E', '#D2ADFA', '#FEADD3']
      }
    },
    showValue: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      echartEvents: {
        click: (item) => {
          this.$emit('echartEvents', item)
        },
      },
    }
  },
  computed: {
    ehartData() {
      return {
        columns: ['name', 'value'],
        rows: this.value,
      }
    },
    echartOption() {
      return {
        color: this.modelColor,
        tooltip: {
          trigger: 'item',
          extraCssText: 'box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);',
          formatter: '{b}: {d}%',
          position: 'bottom',
          textStyle: {
            color: '#5E5E5E',
          },
          backgroundColor: '#FFF',
        },
        legend: {
          show: false,
        },
        series: {
          center: ['50%', '50%'],
          radius: ['60%', '85%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          }
        }
      }
    }
  }
}
</script>
